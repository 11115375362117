// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-single-post-js": () => import("./../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-work-index-js": () => import("./../src/templates/WorkIndex.js" /* webpackChunkName: "component---src-templates-work-index-js" */)
}

